.vantagens {
  display: flex;
  justify-content: center;
  background-color: #a88952;
  padding: 2em 0;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
    }
    display: flex;
    flex-direction: column;
    h2{
      @include breakpoint($tablet) {
        font-size: 2em;
      }
      @include breakpoint($celular) {
        font-size: 1.5em;
      }
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 2em;
      color: #ffffff;
      margin: 0;
      text-align: center;
    }
    .lista{
      @include breakpoint($tablet) {
        margin: 3em 0;
        grid-template-columns: repeat(2, 1fr);
      }
      @include breakpoint($celular) {
        margin: 3em 0;
        grid-template-columns: 1fr;
      }
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2em;
      margin: 4em;
      .vantagem{
        display: flex;
        .titulo{
          display: flex;
          height: 60px;
          align-items: center;
          width: 80px;
          justify-content: space-evenly;
          .divisor{
            width: 3px;
            height: 100%;
            background: #80693c;
            margin: 0 1.5em 0 1em;
          }
        }
        .texto{
          flex: 1;
          h3{
            @include breakpoint($tablet) {
              font-size: 1.3em;
            }
            @include breakpoint($celular) {
              font-size: 1.3em;
            }
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.3em;
            color: #ffffff;
            margin: 20px 0;
          }
          p{
            @include breakpoint($tablet) {
              font-size: 1em;
            }
            @include breakpoint($celular) {
              font-size: 1em;
            }
            font-family: 'Lato', sans-serif;
            font-style: italic;
            color: #ffffff;
            font-size: 1em;
          }
        }
      }
    }
    a {
      align-self: center;
      @include calltoaction(#ffffff, #a88952);
    }
  }
}
