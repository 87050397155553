.pghomenagens {
    display: flex;
    justify-content: center;
    padding: 3em 0 0;
    .meio {
        @include breakpoint($full) {
            width: 1200px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
            grid-gap: 2em;
        }
        @include breakpoint($celular) {
            width: 90vw;
        }
        grid-gap: 5em;
        h1 {
            @include breakpoint($tablet) {
                font-size: 2em;
            }
            @include breakpoint($celular) {
                font-size: 1.5em;
            }
            font-family: "Lato", sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 2em;
            color: #2669a0;
        }
        h2{
            font-family: "Lato", sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1em;
            color: #2669a0;
        }
        .lista-homenagens {
            .lista {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 2em;

                h1 {
                    grid-column: 1/3;
                }
            }
            .item {
                .card{
                    box-sizing: border-box;
                    background: url("../img/bg_card_homenagem.png") top center;
                    background-size: cover;
                    min-height: 400px;
                    height: calc(100% - 90px);
                    display: flex;
                    justify-content: space-between;
                    .nome {
                        display: grid;
                        grid-template-columns: 1fr 3fr;
                        grid-gap: 2em;
                        padding: 1em;
                        margin-top: 2em;
                        h2{
                            display: flex;
                            align-items: center;
                            font-family: "Lato", sans-serif;
                            font-size: 2.5em;
                            text-transform: uppercase;
                            color: #ffffff;
                            margin: 0;
                            grid-column: 2;
                        }
                        background-color: #0e4a7c;
                        .logo{
                            display: flex;
                            align-items: baseline;
                            justify-content: center;
                            .pessoa{
                                width: 150px;
                                height: 150px;
                                border-radius: 150px;
                                object-fit: cover;
                            }
                        }
                    }
                    .texto{
                        grid-gap: 2em;
                        padding: 2em;
                        p{
                            font-size: 1.5em;
                            font-family: "Lato", sans-serif;
                            font-style: italic;
                            color: #444242;
                            line-height: 1.2em;
                            span{
                                clear: both;
                                display: block;
                                font-weight: 700;
                                color: #062640;
                                padding-top: 1em;
                                text-align: right;
                            }
                        }
                    }
                    .logoSerra{
                        padding: 1em 0;
                        text-align: center;
                        background: #FFFFFF;
                        margin-bottom: 3em;
                        img{
                            width: 300px;
                        }
                    }
                }
                .compartilhar{
                    @include breakpoint($tablet) {
                        flex-direction: column;
                    }
                    @include breakpoint($celular) {
                        flex-direction: column;
                    }
                    font-family: "Lato", sans-serif;
                    color: #73716c;
                    border-bottom: solid 2px #73716c;
                    padding: 1em 0;
                    margin-bottom: 1em;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    .social{
                        display: flex;
                        .whatsapp{
                            font-family: 'Lato', sans-serif;
                            font-weight: 700;
                            background-color: #5ca038;
                            color: #ffffff;
                            padding: .3em 1em;
                            -webkit-border-radius: 2em;
                            -moz-border-radius: 2em;
                            border-radius: 2em;
                            margin-right: .5em;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex: 1;
                            text-decoration: none;
                            .icone{
                                background: url('../img/icones-obituario.png') no-repeat -47px -35px;
                                width: 19px;
                                height: 19px;
                                margin-right: .5em;
                            }
                        }
                        .facebook{
                            font-family: 'Lato', sans-serif;
                            font-weight: 700;
                            background-color: #2669a0;
                            color: #ffffff;
                            padding: .3em 1em;
                            -webkit-border-radius: 2em;
                            -moz-border-radius: 2em;
                            border-radius: 2em;
                            margin-right: .5em;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex: 1;
                            text-decoration: none;
                            .icone{
                                background: url('../img/icones-obituario.png') no-repeat -29px -32px;
                                width: 11px;
                                height: 26px;
                                margin-right: .5em;
                            }
                        }
                    }
                }
            }
        }
        .formulario{
            font-family: "Lato", sans-serif;
            font-weight: 400;
            padding-bottom: 2em;
            margin-bottom: 2em;
            border-bottom: solid 2px #73716c;
            p{
                font-family: "Lato", sans-serif;
                font-style: italic;
                color: #73716c;
                line-height: 1.2em;
                &.aviso{
                    color: #ed1a3b;
                }
            }
            form{
                display: flex;
                flex-direction: column;
                input{
                    font-family: "Lato", sans-serif;
                    font-weight: 400;
                    padding: .3em;
                    margin-bottom: .5em;
                }
                textarea{
                    padding: .3em;
                    margin-bottom: .5em;
                    height: 10vh;
                    resize: none;
                }
                .file-upload {
                    position: relative;
                    overflow: hidden;
                    background: #ffffff;
                    text-transform: uppercase;
                    border:solid 1px #73716c;
                    color: #73716c;
                    padding: 8px 15px;
                    font-family: "Lato", sans-serif;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 1em;
                    &:hover{
                        transition: .2s all linear;
                        background: #73716c;
                        color: #ffffff;
                    }
                    input.upload {
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin: 0;
                        padding: 0;
                        font-size: 1em;
                        cursor: pointer;
                        opacity: 0;
                        filter: alpha(opacity=0);
                    }
                }
                .btns{
                    .limpar{
                        background: #ffffff;
                        border:solid 1px #73716c;
                        text-transform: uppercase;
                        color: #73716c;
                        padding: 8px 15px;
                        font-family: "Lato", sans-serif;
                        cursor: pointer;
                        transition: .2s all linear;
                        &:hover{
                            transition: .2s all linear;
                            background: #73716c;
                            color: #ffffff;
                        }
                        &.blue{
                            background-color: #2669a0;
                            color: #ffffff;
                            border:solid 1px #2669a0;
                            margin-left: 1em;
                            &:hover{
                                transition: .2s all linear;
                                background: #ffffff;
                                color: #2669a0;
                            }
                        }
                    }
                }
            }
        }
    }
}
.card-homenagem{
    width: 750px;
    .card{
        box-sizing: border-box;
        background: url("../img/bg_card_homenagem.png") top center;
        background-size: cover;
        min-height: 400px;
        .nome {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-gap: 2em;
            padding: 1em;
            margin-top: 2em;
            h2{
                display: flex;
                align-items: center;
                font-family: "Lato", sans-serif;
                font-size: 2.5em;
                text-transform: uppercase;
                color: #ffffff;
                margin: 0;
                grid-column: 2;
            }
            background-color: #0e4a7c;
            .logo{
                display: flex;
                align-items: baseline;
                justify-content: center;
                .pessoa{
                    width: 150px;
                    height: 150px;
                    border-radius: 150px;
                    object-fit: cover;
                }
            }
        }
        .texto{
            grid-gap: 2em;
            padding: 2em;
            p{
                font-size: 1.5em;
                font-family: "Lato", sans-serif;
                font-style: italic;
                color: #444242;
                line-height: 1.2em;
                span{
                    clear: both;
                    display: block;
                    font-weight: 700;
                    color: #062640;
                    padding-top: 1em;
                    text-align: right;
                }
            }
        }
        .logoSerra{
            padding: 1em 0;
            text-align: center;
            background: #FFFFFF;
            margin-bottom: 3em;
            img{
                width: 300px;
            }
        }
    }
}
