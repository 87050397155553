.historia {
  display: flex;
  align-items: center;
  flex-direction: column;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
      iframe{
        height: 12em !important;
      }
    }
    margin: 3em 0;
    .conteudo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;
    }
  }
  .linhatempo {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
    }
    margin-bottom: 4em;
    img{
      max-width: 100%;
      border: solid 5px #2669a0;
    }
    .linha{
      background: url("../img/linhatempo.jpg") center repeat-y;
      .ponto{
        @include breakpoint($celular) {
          margin-top: 2em;
        }
        background: #2669a0;
        border: solid 5px #ffffff;
        width: 20px;
        height: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        margin-top: 5.5em;
        box-sizing: unset;
      }
    }
    .itemimpar{
      @include breakpoint($celular) {
        grid-template-columns: 30px 1fr;
        grid-template-areas: "linha itema" "linha itemb";
      }
      display: grid;
      grid-template-columns: 1fr 30px 1fr;
      grid-template-areas: "itema linha itemb";
      grid-gap: 1em;
      .itema{
        @include breakpoint($celular) {
          text-align: left;
          padding-top: 2em;
        }
        grid-area: itema;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .itemb{
        @include breakpoint($celular) {
          padding-bottom: 2em;
        }
        grid-area: itemb;
      }
      .linha{
        grid-area: linha;
      }
    }
    .itempar{
      @include breakpoint($celular) {
        grid-template-columns: 30px 1fr;
        grid-template-areas: "linha itema" "linha itemb";
      }
      display: grid;
      grid-template-columns: 1fr 30px 1fr;
      grid-template-areas: "itemb linha itema";
      grid-gap: 1em;
      .itema{
        @include breakpoint($celular) {
          text-align: left;
          padding-top: 2em;
        }
        grid-area: itema;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .itemb{
        @include breakpoint($celular) {
          padding-bottom: 2em;
        }
        grid-area: itemb;
      }
      .linha{
        grid-area: linha;
      }
    }
  }
  h1{
    @include breakpoint($tablet) {
      font-size: 2em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2em;
    color: #2669a0;
    margin: 0;
  }
  h2{
    @include breakpoint($tablet) {
      font-size: 1.5em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5em;
    color: #2669a0;
    margin: 0;
  }
  p{
    @include breakpoint($tablet) {
      font-size: 1em;
    }
    @include breakpoint($celular) {
      font-size: 1em;
      text-align: justify;
    }
    font-family: 'Lato', sans-serif;
    color: #73716c;
    font-size: 1em;
  }
}
.frota{
  display: flex;
  align-items: center;
  flex-direction: column;
  h1{
    @include breakpoint($tablet) {
      font-size: 2em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2em;
    color: #2669a0;
    margin: 0 0 1em;
    text-align: center;
  }
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
    }
    .lista > div{
      @include breakpoint($celular) {
        grid-template-columns: 1fr;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2em;
      img{
        width: 100%;
        border: solid 5px #2669a0;
        object-fit: cover;
      }
    }
    margin: 3em 0;
  }
}
