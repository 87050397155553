.beneficios {
    display: flex;
    justify-content: center;
    background: url("../img/bg-beneficios-home.jpg") center;
    background-size: cover;
    .meio {
        @include breakpoint($full) {
            width: 1200px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
        }
        @include breakpoint($celular) {
            width: 90vw;
            grid-template-areas: "conteudo conteudo";
        }
        padding: 3em 0;
        h2 {
            @include breakpoint($celular) {
                font-size: 1.7em;
            }
            font-family: "Lato", sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 2em;
            color: #ffffff;
            margin: 0;
            text-align: center;
            margin-bottom: 1em;
        }
        .conteudo {
            @include breakpoint($celular) {
                flex-direction: column;
            }
            display: flex;
            align-items: center;
            .texto {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                    @include breakpoint($tablet) {
                        padding: 0 2em;
                    }
                    @include breakpoint($celular) {
                        padding: 0 1em;
                    }
                    font-family: "Lato", sans-serif;
                    font-weight: 700;
                    color: #ffffff;
                    font-style: italic;
                    text-align: center;
                    padding: 0 5em;
                }
                a {
                    @include calltoaction(#ffffff, #5ca038, true);
                }
            }
            .slide {
                flex: 1;
                @include breakpoint($celular) {
                    margin-top: 2em;
                }
                .vantagens {
                    @include breakpoint($tablet) {
                        width: 33vw;
                    }
                    @include breakpoint($celular) {
                        width: 70vw;
                        padding: 0 8vw;
                        img {
                            max-width: 30vw;
                        }
                    }
                    background-color: #ffffff;
                    width: 22vw;
                    padding: 0 3vw;
                    display: block;
                    .item {
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                        padding: 1em 2em;
                        width: 100%;
                        .line {
                            width: 2px;
                            height: 80px;
                            background-color: #73716c;
                            margin: 0 1em;
                        }
                        .descricao {
                            font-family: "Lato", sans-serif;
                            font-weight: 700;
                            font-style: italic;
                            color: #73716c;
                        }
                    }
                    .flickity-button {
                        background: transparent;
                    }
                    .flickity-prev-next-button {
                        width: 60px;
                        height: 60px;
                        &.previous {
                            @include breakpoint($tablet) {
                                left: 0;
                            }
                            @include breakpoint($celular) {
                                left: 0;
                            }
                        }
                        &.next {
                            @include breakpoint($tablet) {
                                right: 0;
                            }
                            @include breakpoint($celular) {
                                right: 0;
                            }
                        }
                    }
                    .flickity-button-icon {
                        fill: rgba(38, 105, 160, 1);
                    }
                    .flickity-button:disabled {
                        display: none;
                    }
                }
            }
        }
    }
}
