.planos{
    display: flex;
    align-items: center;
    flex-direction: column;
    .meio {
        @include breakpoint($full) {
          width: 1200px;
        }
        @include breakpoint($monitor) {
          width: 80vw;
        }
        @include breakpoint($tablet) {
          width: 90vw;
        }
        @include breakpoint($celular) {
          width: 90vw;
        }
        h1,h2{
            @include breakpoint($tablet) {
                font-size: 2em;
            }
            @include breakpoint($celular) {
                font-size: 1.5em;
            }
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 2em;
        }
        p{
            @include breakpoint($tablet) {
              font-size: 1em;
            }
            @include breakpoint($celular) {
              font-size: 1em;
              text-align: justify;
            }
            font-family: 'Lato', sans-serif;
            font-size: 1em;
        }
        .apresentacao{
            @include breakpoint($celular) {
                grid-template-columns: 1fr;
                background: #ffffff;
            }
            @include breakpoint($tablet) {
                grid-template-columns: 1fr .6fr;
                background: url('../img/plano_img.jpg') -70px no-repeat;
            }
            width: 100%;
            background: url('../img/plano_img.jpg') top left no-repeat;
            display: grid;
            grid-template-columns: 1fr .7fr .3fr;
            align-items: center;
            height: 28em;
            .texto{
                grid-column: 2;
                h1{
                    color: #2669a0;
                }
                p{
                    color: #73716c;
                }
            }
        }
    }
    .praquem{
        display: flex;
        align-items: center;
        flex-direction: column;
        background: url('../img/plano_section_img.jpg') center;
        background-size: cover;
        padding: 3em 0;
        width: 100%;
        color: #ffffff;
        .meio{
            @include breakpoint($full) {
                width: 800px;
              }
              @include breakpoint($monitor) {
                width: 80vw;
              }
              @include breakpoint($tablet) {
                width: 90vw;
              }
              @include breakpoint($celular) {
                width: 90vw;
            }
        }
        h2{
            text-align: center;
            margin-bottom: 1em;
        }
        .conteudo{
            @include breakpoint($celular) {
                grid-template-columns: 1fr;
            }
            display: grid;
            align-items: center;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 2em;
            img{
                max-width: 100%;
            }
            .texto{
                h4{
                    margin: 0 0 1rem;
                }
                p{
                    &.info{
                        font-style: italic;
                        font-size: .9em;
                        margin: 0;
                    }
                }
            }
        }
    }
    .beneficios_plano{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2em 0;
        h2{
            color: #2669a0;
            text-align: center;
        }
        .itens{
            @include breakpoint($celular) {
              grid-template-columns: 1fr;
            }
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1em;
            margin: .5em 0;
            .item{
              font-family: 'Lato', sans-serif;
              font-weight: 700;
              text-transform: uppercase;
              font-size: 1em;
              color: #73716c;
              display: grid;
              grid-template-columns: 55px auto;
              .icn{
                border: solid 2px #ed1a3b;
                width: 55px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                transform: scale(.7);
              }
              .texto{
                  h3{
                      font-size: 1em;
                      font-weight: 700;
                      margin: 1em 0;
                  }
                  p{
                    font-style: italic;
                    font-weight: 400;
                    text-transform: initial;
                    line-height: 1em;
                  }
              }
            }
        }
    }
}
