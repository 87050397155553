.trabalhe-conoscopg {
  display: flex;
  justify-content: center;
  padding: 3em 0 0;
  background-color: #a88952;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
      grid-template-areas: "formulario" "imagem";
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "imagem formulario";
    grid-gap: 2vh;
    .imagem{
      grid-area: imagem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      img{
        max-width: 100%;
      }
    }
    .formulario {
      grid-area: formulario;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        @include breakpoint($tablet) {
          font-size: 2em;
        }
        @include breakpoint($celular) {
          font-size: 1.5em;
        }
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2em;
        color: #ffffff;
        margin: 0;
      }
      .form-group {
        @include breakpoint($celular) {
          flex-direction: column;
        }
        display: flex;
        margin: 1em 0;

        &.grid {
          @include breakpoint($celular) {
            grid-template-columns: 1fr;
          }
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 1em;
        }

        .radio {
          @include breakpoint($celular) {
            margin: .3em 0;
          }
          margin-left: 1em;
        }

        .item {
          flex: 1;
          display: flex;
        }

        input[type=text], select, textarea {
          flex: 1;
          padding: .3em;
          border: solid 2px #73716c;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          border-radius: .3em;
          vertical-align: top;
          box-sizing: border-box;
          height: 35px;
          width: 100%;
          outline: 0;
        }

        input[type=file] {
          width: 100%;
          outline: 0;
        }

        textarea {
          height: 10em;
        }

        .enviar {
          @include calltoaction(#80693c, #ffffff);
          margin-top: 0 !important;
          padding: .5em !important;
        }
      }
    }
  }
}
