.alert-dismissible .close{
    display:none;
}
.fixed{
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
}
.space{
    @include breakpoint($tablet){
        height: 230px;
    }
    @include breakpoint($celular){
        height: 205px;
    }
    height: 188px;
}
header{
    @include breakpoint($tablet){
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(212,208,206,1) 50%, rgba(212,208,206,1) 100%);
    }
    @include breakpoint($celular){
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 59%, rgba(212,208,206,1) 59%, rgba(212,208,206,1) 100%);
        padding: 1em 0;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 59%, rgba(212,208,206,1) 59%, rgba(212,208,206,1) 100%);
    .meio{
        @include breakpoint($full){
            width: 1200px;
        }
        @include breakpoint($monitor){
            width: 80vw;
            grid-template-columns: 45% auto;
        }
        @include breakpoint($tablet){
            width: 90vw;
            grid-template-columns: 45% auto;
        }
        @include breakpoint($celular){
            width: 90vw;
            grid-template-columns: 60% 40%;
            grid-gap: 0;
        }
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2em;
        .logo{
            @include breakpoint($celular){
                padding: 0;
            }
            @include breakpoint($tablet){
                padding: 1em 0;
            }
            display: flex;
            padding: 1.5em 0;
            img{
                @include breakpoint($tablet){
                    width: 250px;
                }
                @include breakpoint($celular){
                    width: 180px;
                }
                width: 300px;
            }
            .conteudo{
                @include breakpoint($monitor){
                    width: 95%;
                    justify-content: center;
                }
                @include breakpoint($tablet){
                    flex-direction: column;
                    justify-content: center;
                }
                @include breakpoint($celular){
                    flex-direction: column;
                    justify-content: center;
                }
                display: flex;
                width: 100%;
                align-items: center;
                .line{
                    @include breakpoint($tablet){
                        width: 220px;
                        height: 2px;
                        margin: 1em 0;
                    }
                    @include breakpoint($celular){
                        width: 110px;
                        height: 2px;
                        margin: .5em 0;
                    }
                    width: 2px;
                    height: 100%;
                    margin: 0 1em;
                    background: #73716c;
                }
                .slogan{
                    @include breakpoint($celular){
                        font-size: .8em;
                    }
                    font-family: 'Lato', sans-serif;
                    font-weight: 700;
                    font-style: italic;
                    font-size: 1em;
                    color: #73716c;
                }
            }
        }
        .whatsapp{
            background: rgba(212,208,206,1);
            @include breakpoint($monitor){
                padding: 0 1em;
            }
            @include breakpoint($tablet){
                padding: 0 1em;
                flex-direction: column;
            }
            @include breakpoint($celular){
                padding: 0;
                flex-direction: column;
            }
            padding: 2em 0 2em 3em;
            display: flex;
            justify-content: space-around;
            img{
                @include breakpoint($celular){
                    width: 30px;
                }
            }
            .conteudo{
                display: flex;
                justify-content: center;
                align-items: center;
                @include breakpoint($celular){
                    flex-direction: column;
                }
                .texto{
                    margin-left: 1em;
                    .titulo_um{
                        @include breakpoint($celular){
                            font-size: .8em;
                        }
                        font-family: 'Lato', sans-serif;
                        font-weight: 700;
                        font-style: italic;
                        color: #73716c;
                        font-size: 1.1em;
                    }
                    .numero{
                        @include breakpoint($celular){
                            font-size: 1em;
                        }
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 900;
                        font-size: 1.5em;
                        display: flex;
                        align-items: end;
                        small{
                            font-size: .6em;
                            color: #5ca038;
                            padding-right: .3em;
                        }
                        p{
                            margin: 0;
                            padding: 0;
                            line-height: .9em;
                            color: #2f631c;
                        }
                    }
                }
            }
        }
    }
}
