.inicial{
    .logo{
        padding: 2em 0;
        display: flex;
        justify-content: center;
        img{
            @include breakpoint($tablet) {
                width: 40vw;
            }
            @include breakpoint($celular) {
                width: 70vw;
            }
        }
    }
    .faixa_fone{
        display: grid;
        grid-template-columns: 1fr 1.6fr;
        @include breakpoint($celular) {
            grid-template-columns: 1fr 3fr;
        }
        .icone{
            background-color: #2f631c;
            padding: .3em 1em;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .fone{
            @include breakpoint($tablet) {
                font-size: 1em;
            }
            @include breakpoint($celular) {
                font-size: 1em;
                display: flex;
                flex-direction: column;
                align-items: end;
            }
            background-color: #5ca038;
            color: #ffffff;
            padding: .3em 1em;
            display: flex;
            align-items: center;
            font-size: 1.5em;
            strong{
                font-weight: 800;
                font-size: 1.5em;
                margin-left: .2em;
            }
        }
    }
    .unidades{
        background: url('../img/big_brasao.png') #2669a0 right center no-repeat;
        h1{
            font-weight: 700;
            font-style: italic;
            font-size: 1.8em;
            color: #ffffff;
            text-align: center;
        }
        .itens{
            @include breakpoint($tablet) {
                grid-template-columns: 1fr 1fr;
            }
            @include breakpoint($celular) {
                grid-template-columns: 1fr;
            }
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-gap: 3em;
            margin-top: 3em;
            .item{
                display: flex;
                flex-direction: column;
                &:last-child{
                    @include breakpoint($tablet) {
                        grid-column: 1;
                    }
                    @include breakpoint($celular) {
                        grid-column: 1;
                    }
                    grid-column: 2;
                }
                h3{
                    font-weight: 400;
                    color: #ffffff;
                    font-size: 1.4em;
                    text-transform: uppercase;
                    text-align: center;
                    margin: 0;
                }
                hr{
                    border: solid 1px #ffffff;
                    width: 40%;
                }
                img{
                    width: 100%;
                }
                a{
                    margin-top: -1em;
                    padding: .3em 2em;
                    color: #2669a0;
                    &:hover{
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .footer{
        background: #ffffff;
        padding: 3em 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        hr{
            border: solid 1.5px #00598c;
            width: 15%;
            margin: 2em 0;
        }
        .sociais{
            display: flex;
            height: 33px;
            h3{
                font-weight: 500;
                font-style: italic;
                margin-right: .5em;
                font-size: 1.4em;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                color: #00598c;
            }
            .facebook{
                margin: 0 .3em;
                padding: 8px 17px;
                background: url("../img/footer-sprites-ent.png") no-repeat 0 -33px;
                transition: all .2s linear;
                &:hover{
                    background: url("../img/footer-sprites-ent.png") no-repeat 0 -74px;
                }
            }
            .instagram{
                margin: 0 .3em;
                padding: 8px 17px;
                background: url("../img/footer-sprites-ent.png") no-repeat -45px -33px;
                transition: all .2s linear;
                &:hover{
                    background: url("../img/footer-sprites-ent.png") no-repeat -45px -74px;
                }
            }
        }
    }
}
