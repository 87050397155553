.top-nav {
    background: rgba(38, 105, 160, .9);
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    z-index: 2;
    position: relative;
    @include breakpoint($tablet){
        justify-content: end;
        height: 50px;
    }
    @include breakpoint($celular){
        justify-content: end;
        height: 50px;
    }
    ul {
        margin: 0;
        padding: 0;
        //@include breakpoint($full){
        //    width: 1200px;
        //}
        //@include breakpoint($monitor){
        //    width: 90vw;
        //}
        @include breakpoint($tablet){
            width: 100vw;
            flex-direction: column;
            align-items: baseline;
            list-style: none;
            overflow: auto;
            position: absolute;
            background: rgba(38, 105, 160, .9);
            margin-top: 49px;
            margin-bottom: 30px;
        }
        @include breakpoint($celular){
            width: 100vw;
            height: 45vh;
            flex-direction: column;
            align-items: baseline;
            list-style: none;
            overflow: auto;
            position: absolute;
            background: rgba(38, 105, 160, .9);
            margin-top: 49px;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        li{
            &.hidden-desktop {
                display: none;
            }
            @include breakpoint($tablet){
                display: block;
                width: 100%;
                &.hidden-desktop {
                    display: block;
                }
            }
            @include breakpoint($celular){
                display: block;
                width: 100%;
                &.hidden-desktop {
                    display: block;
                }
            }
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            a{
                @include breakpoint($monitor){
                    font-size: 1em;
                }
                @include breakpoint($tablet){
                    font-size: 1em;
                }
                @include breakpoint($celular){
                    font-size: 1em;
                }
                color: #ffffff;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 1em;
                font-family: 'Lato', sans-serif;
                font-weight: 700;
                height: 100%;
                box-sizing: border-box;
                transition: all .2s linear;
                display: flex;
                align-items: center;
                padding: 1em;
                &.active, &:hover{
                    background: #033254;
                    transition: all .2s linear;
                }
                &.segvia{
                    background: #a42c43;
                }
            }
            > ul {
                position: absolute;
                top: 60px;
                background: rgba(38,105,160,0.9);
                width: max-content;
                opacity: 0;
                visibility: hidden;
                transition: all .3s linear;
                @include breakpoint($celular){
                    display: none;
                }
            }
            &:hover > ul {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .menu {
        @include breakpoint($full){
            max-height: inherit;
        }
        @include breakpoint($monitor){
            max-height: inherit;
        }
        clear: both;
        max-height: 0;
        transition: max-height .2s ease-out;
    }
    .menu-icon {
        @include breakpoint($full){
            display: none;
        }
        @include breakpoint($monitor){
            display: none;
        }
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 1em;
        position: relative;
        user-select: none;
        .navicon {
            background: #ffffff;
            display: block;
            height: 3px;
            position: relative;
            transition: background .2s ease-out;
            width: 27px;
            &:before, &:after {
                background: #ffffff;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                transition: all .2s ease-out;
                width: 100%;
            }
            &:before {
                top: 9px;
            }
            &:after {
                top: -9px;
            }
        }
    }
    .menu-btn {
        display: none;
        &:checked ~ .menu {
            max-height: 500px;
        }
        &:checked ~ .menu-icon .navicon {
            background: transparent;
        }
        &:checked ~ .menu-icon .navicon:before {
            transform: rotate(-45deg);
        }
        &:checked ~ .menu-icon .navicon:after {
            transform: rotate(45deg);
        }
        &:checked ~ .menu-icon:not(.steps) .navicon:before,
        &:checked ~ .menu-icon:not(.steps) .navicon:after {
            top: 0;
        }
    }
}
