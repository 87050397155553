.plano {
  display: flex;
  justify-content: center;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-areas: "conteudo conteudo";
    }
    display: grid;
    grid-template-areas: "imagem conteudo";
    grid-template-columns: 1fr 1fr;
    padding: 3em 0 0;
    .imagem{
      @include breakpoint($celular) {
        display: none;
      }
      grid-area: imagem;
      display: flex;
      align-items: flex-end;
      img{
        width: 100%;
      }
    }
    .conteudo{
      grid-area: conteudo;
      flex-direction: column;
      justify-content: space-around;
      h2{
        @include breakpoint($tablet) {
          font-size: 2em;
        }
        @include breakpoint($celular) {
          font-size: 1.5em;
        }
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2.5em;
        color: #ed1a3b;
        margin: 0;
      }
      p{
        @include breakpoint($tablet) {
          font-size: 1em;
        }
        @include breakpoint($celular) {
          font-size: 1em;
        }
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        color: #73716c;
        font-size: 1.2em;
      }
      .line{
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, #73716c 0%, #73716c 70%, #ea1117 70%, #ea1117 100%);
      }
      .itens{
        @include breakpoint($celular) {
          grid-template-columns: 1fr;
        }
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: .5em 0;
        .item{
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 1em;
          color: #73716c;
          display: flex;
          align-items: center;
          .icn{
            border: solid 2px #ed1a3b;
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            transform: scale(.7);
            .icone{
              background-image: url('../img/spritesheet.png');
              &.urna-mortuaria{
                background-position: 0 -625px;
                width: 17px;
                height: 40px;
              }
              &.sala-velorio{
                background-position: 0 -205px;
                width: 32px;
                height: 37px;
              }
              &.paramentacao{
                background-position: 0 -340px;
                width: 28px;
                height: 35px;
              }
              &.coroa-flores{
                background-position: 0 -41px;
                width: 39px;
                height: 25px;
              }
              &.certidao-obito{
                background-position: 0 -306px;
                width: 30px;
                height: 32px;
              }
              &.transporte{
                background-position: 0 0;
                width: 41px;
                height: 20px;
              }
              &.velas{
                background-position: 0 -441px;
                width: 25px;
                height: 39px;
              }
              &.veu{
                background-position: 0 -68px;
                width: 38px;
                height: 30px;
              }
              &.carro-assistencial{
                background-position: 0 -22px;
                width: 39px;
                height: 17px;
              }
              &.encomendacao{
                background-position: 0 -555px;
                width: 19px;
                height: 36px;
              }
              &.kit-cafe{
                background-position: 0 -418px;
                width: 27px;
                height: 21px;
              }
              &.tercos{
                background-position: 0 -482px;
                width: 23px;
                height: 38px;
              }
              &.assistencia-luto{
                background-position: 0 -593px;
                width: 18px;
                height: 30px;
              }
              &.orientacao-juridica{
                background-position: 0 -244px;
                width: 31px;
                height: 27px;
              }
              &.tanatopraxia{
                background-position: 0 -273px;
                width: 31px;
                height: 31px;
              }
              &.assistencia-local{
                background-position: 0 -100px;
                width: 33px;
                height: 33px;
              }
              &.crematorio{
                background-position: 0 -522px;
                width: 19px;
                height: 31px;
              }
              &.cerimonial{
                background-position: 0 -135px;
                width: 33px;
                height: 33px;
              }
              &.violino{
                background-position: 0 -377px;
                width: 28px;
                height: 39px;
              }
              &.ornamentacao{
                background-position: 0 -170px;
                width: 33px;
                height: 33px;
              }
            }
          }
        }
      }
      .saiba_mais{
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
          flex: 3;
          font-size: 1em;
          font-style: italic;
        }
        a{
          @include calltoaction(#ed1a3b, #ffffff);
          margin-left: 3em;
        }
      }
    }
  }
}