$maincolor: #0069a3;

.blogpg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .menu {
    margin-top: 0;
    width: 100%;
  }
  .titulo {
    width: 100%;
    background: $maincolor;
    padding: 4em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 2em;
      color: #ffffff;
      margin: 0;
      z-index: 2;
      align-self: flex-start;
      strong {
        font-weight: 900;
      }
    }
  }
  .meio {
    @include breakpoint($full) {
      width: 1000px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    .cabecalho {
      padding: 4em 0;
      h2 {
        text-transform: uppercase;
        text-align: center;
        &::before {
          position: absolute;
          content: "";
          width: 40px;
          height: 5px;
          background: $maincolor;
          margin-top: -0.5em;
        }
      }
    }
    .noticias {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 3em;
      @include breakpoint($celular) {
        grid-template-columns: 1fr;
      }
      .paginacao {
        grid-column: 1/3;
        @include breakpoint($celular) {
          grid-column: 1;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: center;
          .page-item {
            border-top: solid 1px $maincolor;
            border-bottom: solid 1px $maincolor;
            min-width: 35px;
            height: 35px;
            display: flex;
            &:first-child,
            &:last-child {
              border-left: solid 1px $maincolor;
              border-right: solid 1px $maincolor;
            }
          }
          .page-link {
            color: $maincolor;
            text-decoration: none;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
          .page-item.active {
            background-color: $maincolor;
            border: solid 1px $maincolor;

            .page-link {
              color: #ffffff;
            }
          }
        }
      }
      .estiloUm {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        @include breakpoint($celular) {
          grid-template-columns: 1fr;
        }
        .img {
          width: 100%;
          height: 100%;
          @include breakpoint($celular) {
            height: 40vh;
          }
        }
        .texto {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 1em;
          transition: all 0.3s linear;
          .tag {
            color: #ffffff;
            background-color: $maincolor;
            text-transform: uppercase;
            font-style: italic;
            font-weight: 600;
            display: block;
            padding: 0.2em 1em 0.2em 1.5em;
            @include breakpoint($celular) {
              padding: 0.2em 1em 0.2em 1em;
              margin-top: 0.5em;
            }
          }
          h2 {
            font-weight: 900;
            padding-left: 1em;
            line-height: 1em;
            font-size: 1.5em;
            margin: 0.3em 0;
            text-transform: uppercase;
            @include breakpoint($celular) {
              padding-left: 0;
            }
          }
          .data {
            font-style: italic;
            padding-left: 1.8em;
            font-size: 0.8em;
            color: $maincolor;
            @include breakpoint($celular) {
              padding-left: 0;
            }
          }
          p {
            line-height: 1em;
            padding-left: 1.5em;
            font-weight: 400;
            font-style: italic;
            @include breakpoint($celular) {
              padding-left: 0;
            }
          }
          a {
            align-self: flex-end;
            color: $maincolor;
            display: flex;
            align-items: center;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
              color: #000000;
            }
          }
        }
      }
    }
    .featured {
      position: relative;
      background: url("../img/noimg_not.png") center;
      background-size: cover;
      min-height: 400px;
      width: 100%;
      margin-top: 3em;
      @include breakpoint($celular) {
        min-height: 170px;
      }

      .imgnot {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      h1 {
        color: #ffffff;
        font-weight: 900;
        margin: 0;
        @include breakpoint($celular) {
          line-height: 1em;
          font-size: 1.8em;
        }
      }

      .overlay {
        position: absolute;
        padding: 3em 10%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          $maincolor 100%
        );
        color: #ffffff;
        z-index: 2;
        @include breakpoint($celular) {
          display: none;
        }

        p {
          margin: 0;
          @include breakpoint($celular) {
            font-size: 0.9em;
          }
        }
      }
    }
    .noticia_info {
      display: none;
      @include breakpoint($celular) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: #000000;
        padding: 1em 1em 0;
      }

      h1 {
        color: $maincolor;
        font-weight: 800;
        @include breakpoint($celular) {
          line-height: 1em;
          font-size: 1.8em;
        }
      }
    }
    .artigo_corpo {
      width: 80%;
      margin: 0 auto;
      @include breakpoint($celular) {
        width: 90%;
      }

      h1,
      h2,
      h3 {
        color: $maincolor !important;
        font-weight: 700;
      }

      a {
        color: $maincolor;
        text-decoration: underline;
      }

      p {
        line-height: 2em;
        font-size: 1em;
        text-align: justify;
        font-weight: 400;
        @include breakpoint($celular) {
          font-size: 1em;
        }
      }

      img {
        max-width: 100% !important;
        height: auto !important;
      }

      .intro {
        padding-top: 1em;
        color: #717171;

        small {
          font-size: 1em;
        }

        .post-description {
          font-weight: 700;
        }

        .infos {
          p {
            padding: 0;
            margin: 0;
          }

          span {
            color: $maincolor;
            text-transform: uppercase;
          }

          small {
            font-style: italic;
          }
        }

        .autores {
          margin: 1em 0;
          font-style: italic;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .autor {
            font-style: italic;
            margin-bottom: 0.2em;
            margin-right: 1em;
            font-size: 0.9em;
            display: flex;
            align-items: center;

            strong {
              font-size: 1.1em;
            }

            img {
              border-radius: 10em;
              border: solid 2px $maincolor;
              margin-right: 1em;
            }
          }
        }
      }
    }
    .comments {
      width: 80%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 2em;

      h4 {
        font-weight: 900;
      }

      iframe {
        width: 100% !important;
      }
    }
    .tags {
      padding: 0;
      margin: 1.5em 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0 0.8em 0.8em 0;
        @include breakpoint($celular) {
          margin: 0.3em 0;
          flex: 1;
          flex-basis: 100%;
          text-align: center;
        }

        a {
          padding: 0.5em 2em;
          background: #ececec;
          font-weight: 700;
          color: #000000;
          display: block;
          text-decoration: none;
          transition: all 0.3s linear;

          &:hover {
            transition: all 0.3s linear;
            background: $maincolor;
          }
        }
      }
    }
    .redes {
      margin: 1.5em 32em;
      display: flex;
      justify-content: space-evenly;
      @include breakpoint($celular) {
        margin: 1.5em 0;
        justify-content: space-evenly;
      }
      a {
        color: $maincolor;
        font-size: 2em;
        transition: all 0.3s linear;
        margin: 0 0.2em;
        &:hover {
          color: darken($color: $maincolor, $amount: .3);
          transition: all 0.3s linear;
        }
        @include breakpoint($celular) {
          font-size: 2.5em;
          margin-right: 0;
        }
        @include breakpoint($celular) {
          &.whats {
            display: none;
          }
        }
      }
      .whats {
        @include breakpoint($celular) {
          display: block;
        }
        display: none;
      }
    }
  }
  .itens {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    position: relative;
    margin-bottom: 5em;
    width: 100%;
    background: url("../images/bgblog.png") no-repeat top;
    background-size: contain;
    .categoriasItens {
      width: 100%;
      background-color: #ededed;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .categorias {
        padding: 1em 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        @include breakpoint($celular) {
          flex-direction: column;
        }
        li {
          a {
            color: $maincolor;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.2s linear;
            font-size: 1em;
            font-weight: 900;
            display: block;
            padding: 0.5em 0;
            &:hover,
            &.active {
              color: #000000;
              transition: all 0.2s linear;
            }
          }
        }
      }
    }
  }
}
