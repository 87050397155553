@mixin calltoaction($primary-color, $secondary-color, $transparent:false){
  @include breakpoint($tablet){
    padding: 1em 2em;
    margin-top: 1em;
  }
  @include breakpoint($celular){
    margin-top: 1em;
    font-size: .8em;
    padding: .8em 1.4em;
    border: solid 1px $primary-color;
  }
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  @if $transparent {
    color: $primary-color;
  } @else {
    color: $secondary-color;
  }
  text-decoration: none;
  border: solid 2px $primary-color;
  @if $transparent {
    background-color: transparent;
  } @else {
    background-color: $primary-color;
  }
  padding: .7em 2em;
  font-size: 1em;
  font-style: normal;
  text-align: center;
  transition: all .3s linear;
  &:hover{
    transition: all .3s linear;
    @if $transparent {
      background: $primary-color;
      color: $secondary-color;
    } @else {
      background: $secondary-color;
      color: $primary-color;
    }
  }
}