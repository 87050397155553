.convalescenca {
    display: flex;
    align-items: center;
    margin: 3em 0 0;
    flex-direction: column;
    .meio {
        @include breakpoint($full) {
            width: 1200px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
        }
        @include breakpoint($celular) {
            width: 90vw;
        }
        h1{
            @include breakpoint($tablet) {
                font-size: 2em;
            }
            @include breakpoint($celular) {
                font-size: 1.5em;
            }
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 2em;
            color: #2669a0;
            text-align: center;
        }
        p{
            @include breakpoint($tablet) {
              font-size: 1em;
            }
            @include breakpoint($celular) {
              font-size: 1em;
              text-align: justify;
            }
            font-family: 'Lato', sans-serif;
            font-size: 1em;
        }
        .content{
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            @include breakpoint($celular) {
                grid-template-columns: 1fr;
            }
            img{
                @include breakpoint($celular) {
                    grid-row: 2;
                }
                max-width: 100%;
            }
        }
        .itens{
            @include breakpoint($celular) {
                grid-row: 1;
            }
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 3em 0;
            .item{
              font-family: 'Lato', sans-serif;
              font-weight: 700;
              text-transform: uppercase;
              font-size: 1em;
              color: #73716c;
              display: grid;
              grid-template-columns: 55px auto;
              .icn{
                width: 55px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                transform: scale(.7);
              }
              .texto{
                    h3{
                        font-size: 1em;
                        font-weight: 700;
                        margin: 1em 0;
                        color: #045d9b;
                    }
                    p{
                        font-style: italic;
                        font-weight: 400;
                        text-transform: initial;
                        line-height: 1em;
                    }
                }
            }
        }
    }
    .banner_convalescenca{
        @include breakpoint($tablet) {
            font-size: 1.5em;
        }
        @include breakpoint($celular) {
            font-size: 1.5em;
        }
        background: url('../img/bg-convalescenca.png') center;
        background-size: cover;
        padding: 2em 0;
        color: #ffffff;
        font-weight: 400;
        text-align: center;
        font-size: 2.5em;
        width: 100%;
        font-weight: 600;
        line-height: 1em;
    }
}
