.card-obituario{
  width: 1080px;
  background: url("../img/bg_card_obituario.png");
  .topo{
    width: 100%;
    .faixa {
        width: 100%;
        height: 150px;
        background-color: #2669a0;
        mix-blend-mode: multiply;
    }
    .foto {
        width: 100%;
        display: grid;
        grid-template-columns: auto 300px auto;
        z-index: 1;
        position: relative;
        margin-top: -50px;
        .faixas_l {
            display: flex;
            align-items: flex-end;
            padding-bottom: 80px;
            img {
                width: 100%;
            }
        }
        .faixas_r {
            display: flex;
            align-items: flex-end;
            padding-bottom: 80px;
            img {
                transform: scaleX(-1);
                width: 100%;
            }
        }
        .profile {
            width: 300px;
            height: 300px;
            object-fit: cover;
            border-radius: 150px;
            border: solid 8px #2669a0;
        }
    }
    .frase {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        color: #a88952;
        font-size: 2.3em;
        text-align: center;
        padding: 1em 0;
    }
  }
  .nome {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    color: #ffffff;
    background-color: #a88952;
    mix-blend-mode: multiply;
    text-transform: uppercase;
    text-align: center;
    padding: 1em 0;
    font-size: 2.5em;
  }
  .corpo{
    width: 100%;
    background: #2669a0;
    mix-blend-mode: multiply;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .datas{
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      color: #ffffff;
      font-size: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5em 0;
      .nascimento{
        display: flex;
        align-items: center;
        margin-right: 3vw;
        .icone{
          background: url("../img/icones-obituario.png") no-repeat 0 0;
          width: 27px;
          height: 26px;
          margin-right: .5em;
        }
      }
      .morte{
        display: flex;
        align-items: center;
        .icone{
          background: url("../img/icones-obituario.png") no-repeat -47px -4px;
          width: 21px;
          height: 25px;
          margin-right: .5em;
        }
      }
    }
    .horarios{
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-gap: 30px;
      padding: 0 0 3em;
      .horario{
        display: grid;
        grid-template-columns: 7vw auto;
        grid-gap: 2vw;
        align-items: center;
        .tag{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: #ffffff;
          padding: .3em .8em;
        }
        p{
          font-family: 'Lato', sans-serif;
          font-weight: 400;
          color: #ffffff;
          font-size: 1.8em;
          margin: 0;
          padding-right: 15vw;
        }
      }
    }
  }
  .rodape {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 1.7em;
    padding: 2em 3em;
    color: #73716c;
    text-align: center;
    img {
        margin-top: 2em;
        width: 400px;
    }
  }
}
