.materiais{
  display: flex;
  justify-content: center;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    .conteudo{
      padding: 3em 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      h2{
        @include breakpoint($celular){
          font-size: 2em;
        }
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2em;
        color: #2669a0;
        margin: 0;
        text-align: center;
        margin-bottom: 1em;
      }
      p{
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        color: #73716c;
        text-align: center;
      }
      ul{
        @include breakpoint($celular) {
          grid-template-columns: 1fr;
        }
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.1em;
        color: #2669a0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: .5em;
        margin: 2em 0;
        margin: 1rem 0;
        li{
          padding-left: 25px;
          &:before{
            content: '';
            position: absolute;
            width: 19px;
            height: 19px;
            background: url("../img/checkbox.png");
            margin-left: -25px;
          }
        }
      }
      a {
        @include calltoaction(#2669a0, #ffffff);
        margin-top: 1em;
      }
    }
    .imagem{
      @include breakpoint($celular) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      img{
        max-width: 100%;
      }
    }
  }
}
