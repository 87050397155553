.obitos {
  display: flex;
  justify-content: center;
  padding: 2em 0;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    h2{
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      color: #2669a0;
    }
    .pessoa{
      @include breakpoint($celular) {
        flex-direction: column;
      }
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      padding: 2em 0;
      border-bottom: solid 3px #d4d0ce;
      .infos{
        h2{
          @include breakpoint($tablet) {
            font-size: 1.6em;
          }
          @include breakpoint($celular) {
            font-size: 1.6em;
          }
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 2em;
          font-style: italic;
          color: #2669a0;
          margin: 0 0 20px;
        }
        .datas{
          display: flex;
          margin-bottom: 2em;
          .nascimento{
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            color: #73716c;
            display: flex;
            align-items: center;
            margin-right: 1.5em;
            .icone{
              background: url('../img/icones-obituario.png') no-repeat 0 0;
              width: 27px;
              height: 26px;
              margin-right: .5em;
            }
          }
          .morte{
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            color: #73716c;
            display: flex;
            align-items: center;
            .icone{
              background: url('../img/icones-obituario.png') no-repeat -47px -4px;
              width: 21px;
              height: 27px;
              margin-right: .5em;
            }
          }
        }
        .velorio, .sepultamento {
          margin: 1em 0;

          .local {
            display: flex;
            align-items: center;
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            color: #73716c;
            margin: .8em 0;

            .label {
              font-family: 'Lato', sans-serif;
              font-weight: 700;
              text-transform: uppercase;
              font-style: italic;
              color: #ffffff;
              background: #5ca038;
              padding: .2em .7em;
              -webkit-border-radius: 1em;
              -moz-border-radius: 1em;
              border-radius: 1em;
              margin-right: .5em;

              &.cor-sep {
                background: #a88952;
              }
            }
          }

          .horario {
            display: flex;
            align-items: center;
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            color: #73716c;
            margin: .8em 0;

            .label {
              background: #5ca038;
              padding: .2em .3em;
              -webkit-border-radius: 1em;
              -moz-border-radius: 1em;
              border-radius: 1em;
              margin-right: .5em;

              &.cor-sep {
                background: #a88952;
              }

              .icone {
                background: url('../img/icones-obituario.png') no-repeat -2px -35px;
                width: 17px;
                height: 19px;
              }
            }
          }
        }
      }
      .compartilhar{
        @include breakpoint($tablet) {
          padding-left: 1em;
        }
        @include breakpoint($celular) {
          border-top: solid 3px #d4d0ce;
          padding-top: 1em;
          margin-top: 1em;
          padding-left: 0;
          border-left: none;
          width: 100%;
        }
        border-left: solid 3px #d4d0ce;
        padding-left: 4em;
        p{
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          color: #73716c;
        }
        .social{
          display: flex;
          .whatsapp{
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            background-color: #5ca038;
            color: #ffffff;
            padding: .3em 1em;
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
            margin-right: .5em;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            text-decoration: none;
            .icone{
              background: url('../img/icones-obituario.png') no-repeat -47px -35px;
              width: 19px;
              height: 19px;
              margin-right: .5em;
            }
          }
          .facebook{
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            background-color: #2669a0;
            color: #ffffff;
            padding: .3em 1em;
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
            margin-right: .5em;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            text-decoration: none;
            .icone{
              background: url('../img/icones-obituario.png') no-repeat -29px -32px;
              width: 11px;
              height: 26px;
              margin-right: .5em;
            }
          }
        }
        .deixar-msg{
          background-color: #a88952;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          color: #ffffff;
          font-style: italic;
          padding: .5em;
          -webkit-border-radius: .5em;
          -moz-border-radius: .5em;
          border-radius: .5em;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 2em;
          &:hover{
            transition: all .3s linear;
            background: #caae78;
          }
          .divisor{
            height: 35px;
            width: 2px;
            background: #80693c;
          }
          .icone{
            background: url('../img/icones-obituario.png') no-repeat -75px -18px;
            width: 42px;
            height: 40px;
          }
        }
      }
    }
  }
}
