.banner{
    @include breakpoint($tablet){
        margin-top: 0px;
    }
    @include breakpoint($celular){
        margin-top: 0px;
    }
    width: 100%;
    .carousel-cell{
        width: 100%;
        img{
            @include breakpoint($celular){
                height: 200px;
                object-fit: cover;
            }
            width: 100%;
            height: calc(100% + 80px);
        }
        .item{
            position: relative;
            .conteudo{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                .meio{
                    @include breakpoint($full){
                        width: 1200px;
                    }
                    @include breakpoint($monitor){
                        width: 80vw;
                    }
                    @include breakpoint($tablet){
                        width: 90vw;
                        padding-top: 0;
                        padding-right: 80px;
                    }
                    @include breakpoint($celular){
                        width: 90vw;
                        padding-top: 0;
                    }
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    padding-top: 80px;
                    h2{
                        @include breakpoint($tablet){
                            font-size: 2em;
                        }
                        @include breakpoint($celular){
                            font-size: 1.8em;
                        }
                        font-family: 'Lato', sans-serif;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 3em;
                        margin: 0;
                        &.estilo_um{
                            color: #ffffff;
                        }
                        &.estilo_dois{
                            color: #2669a0;
                        }
                        &.right{
                            text-align: right;
                        }
                    }
                    h4{
                        @include breakpoint($tablet){
                            font-size: 1.5em;
                        }
                        @include breakpoint($celular){
                            font-size: 1.5em;
                        }
                        font-family: 'Lato', sans-serif;
                        font-weight: 700;
                        font-style: italic;
                        font-size: 2.5em;
                        margin: 0;
                        &.estilo_um{
                            color: #033254;
                        }
                        &.estilo_dois{
                            color: #ffffff;
                        }
                        &.right{
                            text-align: right;
                        }
                    }
                    a{
                        &.estilo_um{
                            @include calltoaction(#ffffff, #033254, true);
                            @include breakpoint($tablet){
                                margin-top: 1em;
                            }
                            @include breakpoint($celular){
                                margin-top: 1em;
                            }
                            margin-top: 3em;
                        }
                        &.estilo_dois{
                            @include calltoaction(#2669a0, #ffffff, true);
                            @include breakpoint($tablet){
                                margin-top: 1em;
                            }
                            @include breakpoint($celular){
                                margin-top: 1em;
                            }
                            margin-top: 3em;
                        }
                    }
                }
                z-index: 2;
            }
        }
    }
    .flickity-button {
        background: transparent;
    }
    .flickity-prev-next-button {
        @include breakpoint($celular){
            display: none;
        }
        width: 100px;
        height: 100px;
    }
    .flickity-button-icon {
        fill: rgba(38, 105, 160, .6);
    }
    .flickity-button:disabled {
        display: none;
    }
    .flickity-page-dots {
        @include breakpoint($celular){
            bottom: 10px;
        }
        bottom: 30px;
    }
    .flickity-page-dots .dot {
        height: 4px;
        width: 40px;
        margin: 0;
        border-radius: 0;
        background: rgba(38, 105, 160, .7);
    }
    .flickity-page-dots .dot.is-selected {
        background: rgb(38, 105, 160);
    }
}
