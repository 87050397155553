.busca-obituario {
  @include breakpoint($celular) {
    padding: 1em 0;
  }
  display: flex;
  justify-content: center;
  padding: 2em 0;
  background-color: #d4d0ce;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      flex-direction: column;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    font-style: italic;
    color: #73716c;
    font-size: 1.5em;
    .campo {
      @include breakpoint($celular) {
        padding: .2em;
        width: 80vw;
        margin-left: 0;
        margin-top: .5em;
      }
      background: #ffffff;
      border-radius: 50px;
      border: solid 2px #73716c;
      padding: .5em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40vw;
      margin-left: .5em;
      input {
        @include breakpoint($celular) {
          max-width: 85%;
        }
        font-family: 'Lato', sans-serif;
        color: #73716c;
        font-size: 1em;
        border: none;
        outline: 0;
        flex: 1;
      }
      button{
        width: 33px;
        height: 30px;
        background: url("../img/buscar-botao.png") transparent center no-repeat;
        border: none;
        cursor: pointer;
      }
    }
  }
}
