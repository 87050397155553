.bloco_noticias {
    width: 100%;
    display: flex;
    justify-content: center;
    .meio {
        @include breakpoint($full) {
          width: 1200px;
        }
        @include breakpoint($monitor) {
          width: 80vw;
        }
        @include breakpoint($tablet) {
          width: 90vw;
        }
        @include breakpoint($celular) {
          width: 90vw;
          grid-template-columns: 1fr;
        }
        padding: 2em 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2em;
        .bloco {
            background-color: #ebebeb;
            .titulo {
                background-color: #00588c;
                color: #ffffff;
                text-transform: uppercase;
                text-align: center;
                padding: .5em 0;
                font-size: 2em;
                font-weight: 600;
            }
            .content {
                color: #747474;
                padding: 2em;
                &.infos {
                    font-size: 1.2em;
                    font-weight: 600;
                }
                .titulo {
                    text-transform: uppercase;
                    font-size: 1.5em;
                }
                .item {
                    display: flex;
                    flex-direction: column;
                    border-bottom: solid 1px;
                    padding: 1em 0;
                    &:last-child{
                        border-bottom: none;
                    }
                    h2 {
                        font-size: 1.5em;
                        text-transform: uppercase;
                    }
                    a {
                        background-color: #a42c43;
                        color: #ffffff;
                        text-decoration: none;
                        text-transform: uppercase;
                        padding: .3em .5em;
                        align-self: flex-end;
                    }
                }
            }
        }
    }
}
