.cremacao {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url("../img/bannerhm_3.jpg") center;
  @include breakpoint($celular) {
    background: url("../img/bannerhm_3.jpg") right;
  }
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1em;
    margin: 8em 0;
    .conteudo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-column: 2;
    }
  }
  h1{
    @include breakpoint($tablet) {
      font-size: 2em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2em;
    color: #2669a0;
    margin: 0;
  }
  h2{
    @include breakpoint($tablet) {
      font-size: 1.5em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5em;
    color: #2669a0;
    margin: 0;
  }
  p{
    @include breakpoint($tablet) {
      font-size: 1em;
    }
    @include breakpoint($celular) {
      font-size: 1em;
      text-align: justify;
    }
    font-family: 'Lato', sans-serif;
    color: #73716c;
    font-size: 1em;
  }
}
.planocremacao{
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #2669a0;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
      background-size: 100%;
    }
    background: url("../img/brasao.png") right center no-repeat;
    background-size: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3em 0;
    .vant{
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        justify-content: center;
        width: 55%;
        margin: 2em 0;
        .item{
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            img{
                margin: 0;
            }
        }
        .divis{
            width: 4px;
            height: 50px;
            margin: 0 1em;
            background-color: #ffffff;
        }
    }
  }
  h1{
    @include breakpoint($tablet) {
      font-size: 2em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2em;
    color: #ffffff;
    margin: 0;
  }
  p{
    @include breakpoint($tablet) {
      font-size: 1em;
    }
    @include breakpoint($celular) {
      font-size: 1em;
      text-align: justify;
    }
    font-family: 'Lato', sans-serif;
    font-style: italic;
    font-weight: 700;
    color: #ffffff;
    font-size: 1em;
  }
  img{
    margin-top: 2em;
  }
}
.processocinzas{
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(90deg,#d4d0ce 0,#d4d0ce 50%,#5ca038 50%,#5ca038 100%);
  @include breakpoint($celular){
    background: linear-gradient(180deg,#d4d0ce 0,#d4d0ce 50%,#5ca038 50%,#5ca038 100%);
  }
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5em;
    padding: 3em 0;
    .processo{
      display: flex;
      .titulo{
        display: flex;
        height: 60px;
        align-items: center;
        width: 80px;
        justify-content: space-evenly;
        .icone-1{
          background: url("../img/004.png") no-repeat;
          width: 45px;
          height: 54px;
        }
        .divisor{
          width: 3px;
          height: 100%;
          background: #2669a0;
          margin: 0 1.5em 0 1em;
        }
      }
      h3{
        @include breakpoint($tablet) {
            font-size: 1.3em;
        }
        @include breakpoint($celular) {
        font-size: 1.3em;
        }
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.3em;
        color: #2669a0;
        margin: 20px 0;
      }
      p{
        @include breakpoint($tablet) {
          font-size: 1em;
        }
        @include breakpoint($celular) {
          font-size: 1em;
          text-align: justify;
        }
        font-family: 'Lato', sans-serif;
        color: #73716c;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
      }
    }
    .religiao{
      display: flex;
      .titulo{
        display: flex;
        height: 60px;
        align-items: center;
        width: 80px;
        justify-content: space-evenly;
        .icone-1{
          background: url("../img/icone-rel.png") no-repeat;
          width: 32px;
          height: 46px;
        }
        .divisor{
          width: 3px;
          height: 100%;
          background: #2f631c;
          margin: 0 1.5em 0 1em;
        }
      }
      .texto{
        flex: 1;
        h3{
          @include breakpoint($tablet) {
            font-size: 1.3em;
          }
          @include breakpoint($celular) {
            font-size: 1.3em;
          }
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 1.3em;
          color: #ffffff;
          margin: 20px 0;
        }
        p{
          @include breakpoint($tablet) {
            font-size: 1em;
          }
          @include breakpoint($celular) {
            font-size: 1em;
          }
          font-family: 'Lato', sans-serif;
          font-style: italic;
          color: #ffffff;
          font-size: 1em;
        }
      }
    }
  }
  h1{
    @include breakpoint($tablet) {
      font-size: 2em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2em;
    color: #ffffff;
    margin: 0;
  }
  p{
    @include breakpoint($tablet) {
      font-size: 1em;
    }
    @include breakpoint($celular) {
      font-size: 1em;
      text-align: justify;
    }
    font-family: 'Lato', sans-serif;
    font-style: italic;
    font-weight: 700;
    color: #ffffff;
    font-size: 1em;
  }
}
.requisitos{
  display: flex;
  align-items: center;
  flex-direction: column;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
    }
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3em 0;
  }
  h1{
    @include breakpoint($tablet) {
      font-size: 2em;
    }
    @include breakpoint($celular) {
      font-size: 1.5em;
    }
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2em;
    color: #2669a0;
    margin: 0 0 1em;
  }
  p{
    @include breakpoint($tablet) {
      font-size: 1em;
    }
    @include breakpoint($celular) {
      font-size: 1em;
      text-align: justify;
    }
    font-family: 'Lato', sans-serif;
    color: #73716c;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
  }
}
