.unidades {
  display: flex;
  justify-content: center;
  padding: 3em 0;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
    }
    display: flex;
    flex-direction: column;
    h2{
      @include breakpoint($tablet) {
        font-size: 2em;
      }
      @include breakpoint($celular) {
        font-size: 1.5em;
      }
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 2em;
      color: #2669a0;
      margin: 0;
      text-align: center;
    }
    .lista{
      @include breakpoint($tablet) {
        margin: 3em 0;
        grid-template-columns: repeat(2, 1fr);
      }
      @include breakpoint($celular) {
        margin: 3em 0;
        grid-template-columns: 1fr;
      }
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2em;
      margin: 4em;
      .unidade{
        @include breakpoint($full){
          &:nth-last-child(1):nth-child(odd) {
            grid-column: 2;
          }
        }
        @include breakpoint($monitor){
          &:nth-last-child(1):nth-child(odd) {
            grid-column: 2;
          }
        }
        @include breakpoint($tablet){
          &:nth-last-child(1):nth-child(odd) {
            grid-column: 1 / span 2;
            .divisor{
              width: 25%;
            }
          }
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .divisor{
          width: 50%;
          height: 3px;
          background: #2669a0;
        }
        h3{
          @include breakpoint($tablet) {
            font-size: 1.3em;
          }
          @include breakpoint($celular) {
            font-size: 1.3em;
          }
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 1.3em;
          color: #2669a0;
          margin: 20px 0;
        }
        p{
          @include breakpoint($tablet) {
            font-size: 1em;
          }
          @include breakpoint($celular) {
            font-size: 1em;
          }
          font-family: 'Lato', sans-serif;
          font-style: italic;
          color: #73716c;
          font-size: 1em;
        }
      }
    }
    a.link {
      align-self: center;
      @include calltoaction(#ffffff, #a88952);
    }
  }
}
