.servicos{
  width: 100%;
  display: flex;
  justify-content: center;
  background: #dcddde;
  .meio {
    @include breakpoint($full) {
      width: 1200px;
    }
    @include breakpoint($monitor) {
      width: 80vw;
    }
    @include breakpoint($tablet) {
      width: 90vw;
    }
    @include breakpoint($celular) {
      width: 90vw;
      grid-template-columns: 1fr;
    }
    padding: 2em 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2em;
    .servico{
      width: 100%;
      display: flex;
      flex-direction: column;
      &.cremacao{
        border: solid 2px #a88952;
        align-items: initial;
        .topo{
          background: url("../img/brasao_servicos.png") #a88952 98% 0 no-repeat;
          padding: .9em;
          font-size: 1.5em;
          color: #ffffff;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          text-transform: uppercase;
        }
        a {
          @include calltoaction(#a88952, #ffffff);
        }
      }
      &.obituario{
        border: solid 2px #1f5989;
        .topo{
          background: url("../img/brasao_servicos.png") #1f5989 98% -84px no-repeat;
          padding: .9em;
          font-size: 1.5em;
          color: #ffffff;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          text-transform: uppercase;
        }
        a {
          @include calltoaction(#1f5989, #ffffff);
        }
      }
      &.homenagens{
        border: solid 2px #5ca038;
        .topo{
          background: url("../img/brasao_servicos.png") #5ca038 98% -168px no-repeat;
          padding: .9em;
          font-size: 1.5em;
          color: #ffffff;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          text-transform: uppercase;
        }
        a {
          @include calltoaction(#5ca038, #ffffff);
        }
      }
      .conteudo{
        @include breakpoint($tablet) {
          grid-template-columns: 1fr;
        }
        background: #ffffff;
        color: #7b7975;
        font-family: 'Lato', sans-serif;
        font-style: italic;
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex: 1;
        .descricao{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: .9em;
        }
        .imagem{
          @include breakpoint($tablet) {
            display: none;
          }
          width: 100%;
          img{
            width: 100%;
          }
        }
        p{
          margin: 0;
        }
      }
    }
  }
}
