.calltoaction{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #2669a0;
  .meio{
    @include breakpoint($full){
      width: 1200px;
    }
    @include breakpoint($monitor){
      width: 80vw;
    }
    @include breakpoint($tablet){
      width: 90vw;
    }
    @include breakpoint($celular){
      width: 100vw;
      height: 12em;
    }
    background: url("../img/brasao.png") right center no-repeat;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 18em;
    h1{
      @include breakpoint($celular){
        font-size: 1.7em;
      }
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 2em;
      color: #ffffff;
      margin: 0;
    }
    p{
      @include breakpoint($monitor){
        width: 60vw;
      }
      @include breakpoint($tablet){
        width: 80vw;
      }
      @include breakpoint($celular){
        width: 90vw;
        font-size: .8em;
      }
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      color: #ffffff;
      font-style: italic;
      width: 40vw;
      font-size: 1em;
    }
    a {
      @include calltoaction(#ffffff, #033254, true);
    }
  }
}