.politica-privacidade {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 2em;
    .meio {
        @include breakpoint($full) {
            width: 1200px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
        }
        @include breakpoint($celular) {
            width: 90vw;
            grid-template-columns: 1fr;
            iframe {
                height: 12em !important;
            }
        }
    }
}
