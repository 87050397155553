.form-contato {
    display: flex;
    justify-content: center;
    padding: 3em 0;
    .meio {
        @include breakpoint($full) {
            width: 1200px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
            grid-gap: 5vh;
        }
        @include breakpoint($celular) {
            width: 90vw;
            grid-template-columns: 1fr;
        }
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10vh;
        align-items: baseline;
        h2 {
            @include breakpoint($tablet) {
                font-size: 2em;
            }
            @include breakpoint($celular) {
                font-size: 1.5em;
            }
            font-family: "Lato", sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 2em;
            color: #2669a0;
            margin: 0;
        }
        .formulario {
            font-family: "Lato", sans-serif;
            font-weight: 700;
            color: #73716c;
            .form-group {
                @include breakpoint($celular) {
                    flex-direction: column;
                }
                display: flex;
                margin: 1em 0;
                &.grid {
                    @include breakpoint($celular) {
                        grid-template-columns: 1fr;
                    }
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 1em;
                }
                .radio {
                    @include breakpoint($celular) {
                        margin: 0.3em 0;
                    }
                    margin-left: 1em;
                }
                .item {
                    flex: 1;
                    display: flex;
                    input,
                    select {
                    }
                }
                input[type="text"],
                select,
                textarea {
                    flex: 1;
                    padding: 0.3em;
                    border: solid 2px #73716c;
                    font-family: "Lato", sans-serif;
                    font-weight: 700;
                    border-radius: 0.3em;
                    vertical-align: top;
                    box-sizing: border-box;
                    height: 35px;
                }
                textarea {
                    height: 10em;
                }
                .limpar {
                    @include calltoaction(#2669a0, #ffffff, true);
                    margin-top: 0 !important;
                    padding: 0.5em !important;
                }
                .enviar {
                    @include calltoaction(#2669a0, #ffffff);
                    margin-top: 0 !important;
                    padding: 0.5em !important;
                }
            }
        }
        .local {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img {
                margin-bottom: 1em;
                max-width: 100%;
                height: 350px;
            }
            h3 {
                color: #73716c;
                font-size: 1em;
                margin: 1em 0;
            }
            ul {
                padding: 0;
                list-style: none;
                font-family: "Lato", sans-serif;
                font-weight: 400;
                font-size: 1em;
                color: #73716c;
                margin: 1rem 0;
                li {
                    padding-left: 25px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 1em;
                    &.endereco {
                        &:before {
                            content: "";
                            position: absolute;
                            background: url("../img/icones-local.png") no-repeat
                                0 0;
                            width: 25px;
                            height: 31px;
                            margin-left: -35px;
                            transform: scale(1);
                        }
                    }
                    &.telefone {
                        &:before {
                            content: "";
                            position: absolute;
                            background: url("../img/icones-local.png") no-repeat -31px -3px;
                            width: 24px;
                            height: 25px;
                            margin-left: -35px;
                            transform: scale(1);
                        }
                    }
                    &.whatsapp {
                        &:before {
                            content: "";
                            position: absolute;
                            background: url("../img/icones-local.png") no-repeat -94px -3px;
                            width: 26px;
                            height: 26px;
                            margin-left: -35px;
                            transform: scale(1);
                        }
                    }
                    &.horario {
                        &:before {
                            content: "";
                            position: absolute;
                            background: url("../img/icones-local.png") no-repeat -124px -2px;
                            width: 27px;
                            height: 27px;
                            margin-left: -35px;
                            transform: scale(1);
                        }
                    }
                    &.email {
                        &:before {
                            content: "";
                            position: absolute;
                            background: url("../img/icones-local.png") no-repeat -63px -9px;
                            width: 24px;
                            height: 17px;
                            margin-left: -35px;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}
#mapa {
    @include breakpoint($celular) {
        height: 400px;
    }
    width: 100%;
    height: 600px;
    background: #1c94c4;
}
