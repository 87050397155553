.noticias {
    display: flex;
    justify-content: center;
    padding: 3em 0;
    .meio {
        @include breakpoint($full) {
            width: 1200px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
        }
        @include breakpoint($celular) {
            width: 90vw;
        }
        img {
            width: 100%;
        }
    }
}
.repatriação {
    @include breakpoint($tablet) {
        padding: 0em;
    }
    background: #2669a0;
    display: flex;
    justify-content: center;
    margin: 1em 0;
    padding: 1em 0;
    .meio {
        @include breakpoint($celular) {
            flex-direction: column;
        }
        h2,
        h3 {
            @include breakpoint($tablet) {
                text-align: center;
                flex: 1;
            }
            color: #ffffff;
            text-transform: uppercase;
            text-align: center;
        }
        h3 {
            text-align: center;
            font-size: 1em;
        }
        img {
            @include breakpoint($tablet) {
                width: 200px;
            }
            @include breakpoint($celular) {
                margin: 20px 0;
                margin-top: initial;
            }
            margin: 0 20px;
            margin-top: -50px;
            width: 120px;
        }
        display: flex;
        align-items: center;
    }
}
.footer {
    @include breakpoint($tablet) {
        background: linear-gradient(
            90deg,
            #2669a0 0%,
            #2669a0 55%,
            #1f5989 55%,
            #1f5989 100%
        );
    }
    @include breakpoint($celular) {
        background: linear-gradient(
            180deg,
            #2669a0 0,
            #2669a0 50%,
            #1f5989 50%,
            #1f5989 100%
        );
        padding: 1em 0;
    }
    margin: 1em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(
        90deg,
        #2669a0 0%,
        #2669a0 46%,
        #1f5989 46%,
        #1f5989 100%
    );
    .meio {
        @include breakpoint($full) {
            width: 1200px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
            grid-template-columns: 58% auto;
        }
        @include breakpoint($celular) {
            width: 90vw;
            grid-template-columns: 1fr;
        }
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 45% auto;
        padding: 2em 0;
        .logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
            }
            .sociais {
                margin: 1em 0 0;
                display: flex;
                height: 33px;
                .facebook {
                    margin: 0 0.3em;
                    padding: 8px 17px;
                    background: url("../img/footer-sprites.png") no-repeat 0 -33px;
                    transition: all 0.2s linear;
                    &:hover {
                        background: url("../img/footer-sprites.png") no-repeat 0 -74px;
                    }
                }
                .instagram {
                    margin: 0 0.3em;
                    padding: 8px 17px;
                    background: url("../img/footer-sprites.png") no-repeat -45px -33px;
                    transition: all 0.2s linear;
                    &:hover {
                        background: url("../img/footer-sprites.png") no-repeat -45px -74px;
                    }
                }
            }
        }
        .infos {
            @include breakpoint($celular) {
                flex-direction: column;
                align-items: center;
                margin-top: 2em;
                padding-left: 0;
            }
            @include breakpoint($tablet) {
                flex-direction: column;
            }
            display: flex;
            padding-left: 2em;
            .unidade {
                h3 {
                    @include breakpoint($celular) {
                        font-size: 1.3em;
                    }
                    font-family: "Lato", sans-serif;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 1.3em;
                    color: #ffffff;
                    margin: 0;
                }
                ul {
                    padding: 0;
                    list-style: none;
                    font-family: "Lato", sans-serif;
                    font-size: 1em;
                    color: #ffffff;
                    font-style: italic;
                    margin: 1rem 0;
                    li {
                        padding-left: 25px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.5em;
                        &.endereco {
                            &:before {
                                content: "";
                                position: absolute;
                                width: 17px;
                                height: 25px;
                                background: url("../img/footer-sprites.png") 0 0;
                                margin-left: -25px;
                                transform: scale(0.7);
                            }
                        }
                        &.telefone {
                            &:before {
                                content: "";
                                position: absolute;
                                width: 19px;
                                height: 20px;
                                background: url("../img/footer-sprites.png") -29px
                                    0;
                                margin-left: -25px;
                                transform: scale(0.7);
                            }
                        }
                        &.email {
                            &:before {
                                content: "";
                                position: absolute;
                                width: 21px;
                                height: 11px;
                                background: url("../img/footer-sprites.png") -57px
                                    0;
                                margin-left: -25px;
                                transform: scale(0.7);
                            }
                        }
                    }
                }
            }
        }
    }
}
.footer-links {
    background-color: #eae9e8;
    padding: 1em 0;
    display: flex;
    justify-content: center;
    margin: 1em 0;
    .meio {
        @include breakpoint($full) {
            width: 1000px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
        }
        @include breakpoint($celular) {
            width: 90vw;
        }
        ul {
            @include breakpoint($tablet) {
                grid-template-columns: 1fr 1fr;
            }
            @include breakpoint($celular) {
                grid-template-columns: 1fr;
            }
            padding: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1em;
            margin: 1rem 0;
            li {
                @include breakpoint($tablet) {
                    font-size: 1em;
                }
                span {
                    color: #2669a0;
                    font-weight: 700;
                    text-transform: uppercase;
                }
                p {
                    margin: 0;
                }
                text-align: center;
                font-family: "Lato", sans-serif;
                color: #72706b;
                font-size: 0.8em;
                text-decoration: none;
                .sociais {
                    margin: 1em 0 0;
                    display: flex;
                    justify-content: center;
                    height: 33px;
                    .facebook {
                        margin: 0 0.3em;
                        padding: 8px 17px;
                        background: url("../img/footer-sprites.png") no-repeat 0 -74px;
                        transition: all 0.2s linear;
                        &:hover {
                            background: url("../img/footer-sprites.png")
                                no-repeat 0 -33px;
                        }
                    }
                    .instagram {
                        margin: 0 0.3em;
                        padding: 8px 17px;
                        background: url("../img/footer-sprites.png") no-repeat -45px -74px;
                        transition: all 0.2s linear;
                        &:hover {
                            background: url("../img/footer-sprites.png")
                                no-repeat -45px -33px;
                        }
                    }
                }
            }
        }
    }
}
.liv {
    padding: 0 0 5em;
    display: flex;
    justify-content: center;
}
.footer-callaction {
    background: #0069a3;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    width: 100%;
    bottom: 0;
    .meio {
        @include breakpoint($full) {
            width: 1000px;
        }
        @include breakpoint($monitor) {
            width: 80vw;
        }
        @include breakpoint($tablet) {
            width: 90vw;
        }
        @include breakpoint($celular) {
            width: 90vw;
        }
        ul {
            li {
                a {
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    text-decoration: none;
                    &:hover {
                        color: #f1f1f1;
                    }
                }
                .icone {
                    margin-right: 1em;
                    i {
                        font-size: 2em;
                    }
                }
                .conteudo {
                    @include breakpoint($celular) {
                        h3,
                        h2 {
                            display: none;
                        }
                    }
                    h3 {
                        font-family: "Lato", sans-serif;
                        font-weight: 400;
                        text-transform: uppercase;
                        font-size: 0.8em;
                        color: #fff;
                        margin: 0;
                    }
                    h2 {
                        @include breakpoint($tablet) {
                            font-size: 1em;
                        }
                        font-family: "Lato", sans-serif;
                        font-weight: 900;
                        text-transform: uppercase;
                        font-size: 1.2em;
                        color: #fff;
                        margin: 0;
                    }
                }
            }
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            color: #ffffff;
            margin: 1rem 0;
        }
    }
}
.politics-bar {
    position: fixed;
    bottom: 70px;
    width: 100%;
    height: 0;
    opacity: 0;
    background: #00588c;
    background-color: #00588c;
    zoom: 1 !important;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    line-height: 1.5;
    letter-spacing: 0.03em;
    z-index: 1000;
    transition: height 0.6s, opacity 0.6s;
    &.active {
        height: auto;
        opacity: 1;
        padding: 15px 0;
    }
    a {
        color: #ffffff;
        text-decoration: underline;
    }
    .btn-fechar-politica {
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        right: 15px;
        top: 15px;
        cursor: pointer;
        z-index: 1000;
    }
    .btn-aceite {
        padding: 3px 13px;
        background-color: #fff;
        color: #00588c;
        border: 1px solid transparent;
        transition: all 0.15s ease-in-out;
        text-decoration: none;
    }
}
